import React from "react";
import College from "/Users/mohammedaariz/Desktop/site/cs_site/cs_site_react/src/assets/images/CollegeBanner/CollegeLogo.png";

const CollegeBanner = () => {


return <div><img style={{width:"100%",height:"250px"}} src={College}></img></div>

}








export default CollegeBanner;