export const scholarsData = [
    {
        data: [
            {
                supervisorName: "Dr. B. PARVATHA VARTHINI",
                university: "Anna University",
                recoNo: "2340184"
            },
            {
                supervisorName: "Dr. A Chandrasekar",
                university: "Anna University",
                recoNo: "2940058"
            },
            {
                supervisorName: "Dr. A Chandrasekar",
                university: "Annamalai University",
                recoNo: "-"
            },
            {
                supervisorName: "Dr. S Jothi",
                university: "Anna University",
                recoNo: "3940002"
            },
            {
                supervisorName: "Dr. G.Maria Kalavathy",
                university: "Anna University",
                recoNo: "2340184"
            },
            {
                supervisorName: "Dr. B Diwan",
                university: "Anna University",
                recoNo: "2940108"
            },
            {
                supervisorName: "Dr. K Palani Thanaraj",
                university: "Anna University",
                recoNo: "3340037"
            },
            {
                supervisorName: "Dr.F Sangeetha Francelin Vinnarasi",
                university: "Anna University",
                recoNo: "4140081"
            },
           
            
        ]
    }
]