// import angel from "../../assets/images/faculty/TCS63.jpg";
// import ajoe from "../../assets/images/faculty/TCS45.JPG";
import default_pic from "../../assets/images/faculty//default.jpg";
import anita from "../../assets/images/faculty//TCS22.jpg";
import chandru from "../../assets/images/faculty/TCS03.jpg";
import diwan from "../../assets/images/faculty/TCS07.jpg";
import hemalatha from "../../assets/images/faculty/TCS10.jpg";
import janani from "../../assets/images/faculty/TCS71.JPG";
// import jei from "../../assets/images/faculty/TCS13.jpg";
// import jesline from "../../assets/images/faculty/TCS09.jpg";
// import maria from "../../assets/images/faculty/TCS02.jpg";
// import vroon from "../../assets/images/faculty/TCS43.jpg";
// import sangeetha from "../../assets/images/faculty/TCS28.jpg";
// import rajakumar from "../../assets/images/faculty/TCS61.JPG";
import ranjith from "../../assets/images/faculty/TCS57.JPG";
// import pugalenthi from "../../assets/images/faculty/TCS20.jpg";
// import prabhu from "../../assets/images/faculty/TCS44.jpg";
// import naveen from "../../assets/images/faculty/TCS48.jpg";
// import murugesan from "../../assets/images/faculty/TCS04.jpg";
// import ph from "../../assets/images/faculty/placeholder.jpg";
// import anjana from "../../assets/images/faculty/TCS60.jpg";
// import jean from "../../assets/images/faculty/TCS52.jpg";
// import mani from "../../assets/images/faculty/TCS42.JPG";
import shalini from "../../assets/images/faculty/TCS11.jpg";
// import rosy from "../../assets/images/faculty/TCS18.jpg";
// import jothi from "../../assets/images/faculty/TCS24.jpg";
import sudha from "../../assets/images/faculty/TCS64.jpg";
// import ramya from "../../assets/images/faculty/TCS62.jpg";
import shanthini from "../../assets/images/faculty/TCS45.JPG";
// import anuradha from "../../assets/images/faculty/TCS47.JPG";
import rajaganesh from "../../assets/images/faculty/TCS66.jpg";
import vinu from "../../assets/images/faculty/TCS55.JPG";
// import sheryl from "../../assets/images/faculty/TCS41.JPG";
import brindha from "../../assets/images/faculty/brindha.jpeg";
import narmadha from "../../assets/images/faculty/narmadha.jpeg";
import pandeeswaran from "../../assets/images/faculty/pandeeswaran.JPG";
import uma from "../../assets/images/faculty/uma_maheshwari.jpeg";
import saranya from "../../assets/images/faculty/saranya.jpg";
import raghavi from "../../assets/images/faculty/raghavi.jpeg";
// import parvathavarthini from "../../assets/images/faculty/parvathavarthini.jpeg";
// import palanithangaraj from "../../assets/images/faculty/palanithangaraj.png";
import ahiladevi from "../../assets/images/faculty/ahiladevi.jpg";
// import durai from "../../assets/images/faculty/durai.jpg";
import raghi from "../../assets/images/faculty/raghi.jpg";
import jenitha from "../../assets/images/faculty/jenitha.jpg";
// import kumari from "../../assets/images/faculty/kumari.jpg";
// import sathyavathi from "../../assets/images/faculty/sathyavathi.jpg";

// import lakshmanan from "../../assets/images/faculty/TCS153.jpeg"
// import sagaya from "../../assets/images/faculty/TCS150.jpeg"
// import mohana from "../../assets/images/faculty/TCS154.jpeg"
import jayabharathi from "../../assets/images/faculty/TCS87.JPG";
import reebarose from "../../assets/images/faculty/TCS89.JPG";
import archana from "../../assets/images/faculty/TCS84.JPG";
import lakshmi from "../../assets/images/faculty/TCS85.JPG";
import rajkumar from "../../assets/images/faculty/TCS93.JPG";
import nireshkumar from "../../assets/images/faculty/TCS94.JPG";
import nithya from "../../assets/images/faculty/TCS96.jpg";
import shiny from "../../assets/images/faculty/TCS97.jpg";
import manochitra from "../../assets/images/faculty/TCS98.jpg";
import alexander from "../../assets/images/faculty/TCS99.jpg";
import steffina from "../../assets/images/faculty/TCS102.jpg";
import swaminathan from "../../assets/images/faculty/TCS100.jpg";
import blessedsam from "../../assets/images/faculty/TCS101.jpg";
import muthulakshmi from "../../assets/images/faculty/TCS01.jpg";



const faculty_details = [
    [
        // {
        //     image: parvathavarthini,
        //     facultyName: "Dr. B. PARVATHA VARTHINI",
        //     education: "M.Sc, M.B.A. M.E. Ph.D.",
        //     designation: "Dean,  Professor",
        //     pdfLink: "https://stjosephscse.com/pdfs/faculty/PARVATHAVARTHINI.pdf",
        // },
        {
          image:muthulakshmi,
          facultyName:" Dr. V. Muthu Lakshmi",
          education:"M.E., Ph.D.",
          designation:"HOD, Professor",
          pdfLink:""
        },
        {
            image: chandru,
            facultyName: "Dr A CHANDRA SEKAR",
            education: "M.E., Ph.D.",
            designation: "HOD-Staff, Professor",
            pdfLink: "https://stjosephscse.com/pdfs/faculty/CHANDRA_SEKAR.pdf",
        },
        // {
        //     image: maria,
        //     facultyName: "Dr G MARIAKALAVATHY",
        //     education: "M.E., Ph.D.",
        //     designation: "HOD - Student Affairs, Professor",
        //     pdfLink: "https://stjosephscse.com/pdfs/faculty/MARIAKALAVATHY.pdf",
        // },
        
        // {
        //     image: murugesan,
        //     facultyName: "Dr G MURUGESAN",
        //     education: "M.E., Ph.D.",
        //     designation: "Professor",
        //     pdfLink: "https://stjosephscse.com/pdfs/faculty/murugesan.pdf",
        //   },
        // {
        //   image: pugalenthi,
        //   facultyName: "Dr R PUGALENTHI",
        //   education: "M.E., Ph.D.",
        //   designation: "HOD - Lab Affairs, Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/PUGALENTHI.pdf",
        // },
        // {
        //   image: jothi,
        //   facultyName: "Dr S JOTHI",
        //   education: "M.E., Ph.D",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/JOTHI.pdf",
        // },
        {
          image: diwan,
          facultyName: "Dr B DIWAN",
          education: "M.E., Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/DIWAN.pdf",
        },
        // {
        //   image: rajakumar,
        //   facultyName: "Dr M P RAJAKUMAR",
        //   education: "M.E., Ph.D.",
        //   designation: "Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/RAJAKUMAR.pdf",
        // },
          
        // {
        //   image: rosy,
        //   facultyName: "Dr D ROSY SALOMI VICTORIA",
        //   education: "M.E., Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/ROSY.pdf",
        // },
        
        
        {
          image: anita,
          facultyName: "Dr J T ANITA ROSE",
          education: "M.E., Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/ANITAROSE.pdf",
        },
        // {
        //   image: sangeetha,
        //   facultyName: "Dr F SANGEETHA FRANCELIN VINNARASI",
        //   education: "M.Tech, Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/SANGEETHAFRANCELINVINNARASI.pdf",
        // },
        {
          image: hemalatha,
          facultyName: "Dr R HEMALATHA",
          education: "M.E., Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/HEMALATHA.pdf",
        },
          
        // {
        //   image: jesline,
        //   facultyName: "Dr JESLINE",
        //   education: "M.E., Ph.D",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/JESLINE.pdf",
        // },
        // {
        //   image: sheryl,
        //   facultyName: "Dr A SHERYL OLIVER",
        //   education: "M.Tech, Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/SHERYLOLIVER.pdf",
        // },
        // {
        //   image: anuradha,
        //   facultyName: "Dr M ANURADHA",
        //   education: "M.E, Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/anuradha.pdf",
        // },
        // {
        //   image: jean,
        //   facultyName: "Dr J JEAN JUSTUS",
        //   education: "M.Tech, Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/JEANJUSTUS.pdf",
        // },
        // {
        //   image: anjana,
        //   facultyName: "Dr V ANJANA DEVI",
        //   education: "MCA, M.E., Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/anjana.pdf",
        // },
        // {
        //   image: ramya,
        //   facultyName: "Dr J RAMYA",
        //   education: "MCA, M.E., Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/Ramya.pdf",
        // },
        // {
        //   image: angel,
        //   facultyName: "Dr N Angel",
        //   education: "M.C.A., M.E., Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/angel.pdf",
        // },
        {
          image: uma,
          facultyName: "Dr. B. UMA MAHESWARI",
          education: "MCA, M.E., Ph.D.",
          designation: "Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/UMAMAHESWARI.pdf",
        },
        // {
        //   image: ph,
        //   facultyName: "Dr. N. MYTHILI",
        //   education: "MCA, M.E., Ph.D.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/mythili.pdf",
        // },
        {
          image: pandeeswaran,
          facultyName: "Dr. C. PANDEESWARAN",
          education: "M.E., Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/PANDEESWARAN.pdf",
        },
        {
          image: brindha,
          facultyName: "Dr. G. BRINDHA",
          education: "M.E., Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/BRINDHA.pdf",
        },
        
        // {
        //   image: palanithangaraj,
        //   facultyName: "Dr. K. PALANI THANARAJ",
        //   education: "M.E., Ph.D.",
        //   designation: "Associate Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/PalaniThanaraj.pdf",
        // },
        {
          image: ahiladevi,
          facultyName: "Dr. E. AHILA DEVI",
          education: "M.E, Ph.D.",
          designation: "Associate Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Ahila_Devi.pdf",
        },
        {
          image: shalini,
          facultyName: "Ms. M SHALINI",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/SHALINI.pdf",
        },
        // {
        //   image: ph,
        //   facultyName: "Ms A AJOE SWEETLIN JEENA",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/ajoe.pdf",
        // },
        // {
        //   image: jei,
        //   facultyName: "Ms P N JEIPRATHA",
        //   education: "M.E., (Ph.D.)",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/JEIPRATHA.pdf",
        // },
        // {
        //   image: balaji,
        //   facultyName: "Mr K BALAJI",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/balaji.pdf",
        // },
        // {
        //   image: mani,
        //   facultyName: "Dr N MANIKANDAN",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/balaji.pdf",
        // },
        // {
        //   image: prabhu,
        //   facultyName: "Mr A PRABHU CHAKKARAVARTHY",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/prabhu.pdf",
        // },
        // {
        //   image: vroon,
        //   facultyName: "Mr P VARUN",
        //   education: "M.Tech., (Ph.D.)",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/VARUN.pdf",
        // },
        {
          image: shanthini,
          facultyName: "Ms S SHANTHINI",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/SHANTHINI.pdf",
        },
        // {
        //   image: naveen,
        //   facultyName: "Mr P NAVEEN",
        //   education: "M.E., (Ph.D.)",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/NAVEEN.pdf",
        // },
        {
          image: ranjith,
          facultyName: "Mr R RANJITH",
          education: "M.E., (Ph.D)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/RANJITH.pdf",
        },
        {
          image: vinu,
          facultyName: "Mr S VINU",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/VINU.pdf",
        },
        {
          image: sudha,
          facultyName: "Ms K SUDHA",
          education: "MCA, M.E.",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Sudha.pdf",
        },
        {
          image: rajaganesh,
          facultyName: "Mr K RAJAGANESH",
          education: "M.E., MCA",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Rajaganesh.pdf",
        },
        
        {
          image: janani,
          facultyName: "Ms S JANANI",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Janani.pdf",
        },
     
        // {
        //   image: ph,
        //   facultyName: "Dr. K. Parkavi",
        //   education: "M.E., Ph.D",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/parkavi.pdf",
        // },
        
        // {
        //   image: ph,
        //   facultyName: "Ms Jenif D Souza W S",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/jenif.pdf",
        // },
        // {
        //   image: durai,
        //   facultyName: "Mr DURAI RAJI V",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/DuraiRaji.pdf",
        // },
        {
          image: saranya,
          facultyName: "Ms. D. SARANYA",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/SARANYA.pdf",
        },
        {
          image: raghavi,
          facultyName: "Ms. S. RAGHAVI",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/RAGHAVI.pdf",
        },
        {
          image: raghi,
          facultyName: "Ms. K.R. RAGHI",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Raghi.pdf",
        },
        {
          image: jenitha,
          facultyName: "Ms. T. JENITHA",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Jenitha.pdf",
        },
        // {
        //   image: kumari,
        //   facultyName: "Ms. KUMARI DEEPIKA",
        //   education: "M.E., (Ph.D)",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/KumariDeepika.pdf",
        // },
        // {
        //   image: sathyavathi,
        //   facultyName: "Ms. S. SATHYAVATHI",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/Sathiyavathi.pdf",
        // },
        {
          image: narmadha,
          facultyName: "Ms. K. Narmatha",
          education: "M.E., (Ph.D.)",
          designation: "Assistant Professor",
          pdfLink: "https://stjosephscse.com/pdfs/faculty/Narmatha.pdf",
        },
        // {
        //   image: lakshmanan,
        //   facultyName: "Mr LAKSHMANAN S",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/jenif.pdf",
        // },
        // {
        //   image: sagaya,
        //   facultyName: "Ms SAGAYA KALAISELVI A",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/jenif.pdf",
        // },
        // {
        //   image: mohana,
        //   facultyName: "Ms MOHANA PADMA V",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "https://stjosephscse.com/pdfs/faculty/jenif.pdf",
        // },
        {
          image: jayabharathi,
          facultyName: "Mrs Jayabharathi P",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        {
          image: lakshmi,
          facultyName: "Mrs Lakshmi S",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        {
          image: archana,
          facultyName: "Ms Archana Jenis M R",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        {
          image: reebarose,
          facultyName: "Ms Reeba Rose L",
          education: "M.Tech.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Deepa D",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Ananthi S",
        //   education: "M.E., (Ph.D)",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        {
          image: nireshkumar,
          facultyName: "Mr Niresh Kumar S",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Sathya V",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        
        {
          image: rajkumar,
          facultyName: "Mr Rajkumar K",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        // {
        //   image: default_pic,
        //   facultyName: "Mr Senthil Kumar D",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Mr Sathish Kumar R",
        //   education: "M.Tech.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Meera Devi G",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Dolly Jinu R",
        //   education: "M.E.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Nisha A S",
        //   education: "M.E., (Ph.D)",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        // {
        //   image: default_pic,
        //   facultyName: "Ms Jayasri R",
        //   education: "M.Tech.",
        //   designation: "Assistant Professor",
        //   pdfLink: "",
        // },
        {
          image: default_pic,
          facultyName: "Mr Thalapathy S",
          education: "M.E.",
          designation: "Assistant Professor",
          pdfLink: "",
        },
        {
          image: manochitra,
          facultyName:"Ms. Manochithra S",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },
        {
          image: nithya,
          facultyName:"Ms. Nithya A",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },
        {
          image: shiny,
          facultyName:"Ms. Shiny R M",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },
        {
          image: alexander,
          facultyName:"Mr. Alexander R",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },
        {
          image: steffina,
          facultyName:"Ms. Steffina Morin",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },
        {
          image: swaminathan,
          facultyName:"Mr. K. Swaminathan",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        },{
          image: blessedsam,
          facultyName:"Mr. Blessed Sam",
          education:"M.E.",
          designation:"Assistant Professor",
          pdfLink:""
        }
    ],
];

export default faculty_details;
