import front1 from "../../assets/images/frontpage/front1.jpeg";
import front2 from "../../assets/images/frontpage/front2.png";
import front3 from "../../assets/images/frontpage/front3.jpg";
import front4 from "../../assets/images/frontpage/front4.jpg";
import front7 from "../../assets/images/frontpage/front7.jpeg";
import front8 from "../../assets/images/frontpage/front8.jpeg";
import front9 from "../../assets/images/frontpage/front9.jpeg";
import front10 from "../../assets/images/frontpage/front10.jpeg";
import front12 from "../../assets/images/frontpage/front12.jpg";
import front13 from "../../assets/images/frontpage/front17.jpeg"
import front14 from "../../assets/images/frontpage/front14.jpeg"
import front15 from "../../assets/images/frontpage/front15.jpeg";
import front16 from "../../assets/images/frontpage/front16.jpeg";
import front19 from "../../assets/images/frontpage/front19.jpg";
import front20 from "../../assets/images/frontpage/front20.jpeg";
import front21 from "../../assets/images/frontpage/front21.jpeg";

export const silderInfo = [
  {
    imageURL:
      
      front1,
    heading: "Heading 1",
    content: "Content 1",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front2,
    heading: "Heading 2",
    content: "Content 2",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front4,
    heading: "Heading 3",
    content: "Content 3",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front3,
    heading: "Heading 3",
    content: "Content 3",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front7,
    heading: "Heading 7",
    content: "Content 7",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front8,
    heading: "Heading 8",
    content: "Content 8",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front9,
    heading: "Heading 9",
    content: "Content 9",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front10,
    heading: "Heading 10",
    content: "Content 10",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front12,
    heading: "Heading 12",
    content: "Content 12",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front13,
    heading: "Heading 13",
    content: "Content 13",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front14,
    heading: "Heading 14",
    content: "Content 14",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front15,
    heading: "Heading 15",
    content: "Content 15",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front16,
    heading: "Heading 16",
    content: "Content 16",
  },
  {
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front19,
    heading: "Heading 19",
    content: "Content 19",
  },{
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front20,
    heading: "Heading 20",
    content: "Content 20",
  },{
    imageURL:
      // "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png",
      front21,
    heading: "Heading 21",
    content: "Content 21",
  }
];
export const silderInfo2 = [
  {
    imageURL:
    front1,
    heading: "Heading 1",
    content: "Content 1",
  },
  {
    imageURL:
    front2,
    heading: "Heading 2",
    content: "Content 2",
  },
  
];
