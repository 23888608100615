import { appchallenger,blogmaster,techit,codewars,fullstackdevops,hackothan,hardcode,hellogram,kodefighter,loppsolve,probsolvi,topcoders,udebug } from "./eventindex";

const EventsData = [
    [
        {
            eventName: "APPChallenger",
            image: appchallenger,
            dept: "Department of Computer Science and Engineering & Cognizant Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2023",
            date: "March 17th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "BlogMASTER",
            image: blogmaster,
            dept: "Department of Computer Science and Engineering",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2023",
            date: "March 08th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "HELLOgram",
            image: hellogram,
            dept: "Department of Computer Science and Engineering & Tech Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2023",
            date: "February 20th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "TOPCoder's",
            image: topcoders,
            dept: "Department of Computer Science and Engineering & Google Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2023",
            date: "January 21th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "UDebug",
            image: udebug,
            dept: "Department of Computer Science and Engineering & Cognizant Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2023",
            date: "February 10th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "CODEWARS",
            image: codewars,
            dept: "Department of Computer Science and Engineering & Tech Club",
            firstprice: "Rs 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "October 30th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "FULLStackDevops",
            image: fullstackdevops,
            dept: "Department of Computer Science and Engineering & Tech Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "July 23rd",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "HackOThaan",
            image: hackothan,
            dept: "Department of Computer Science and Engineering",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "July 29th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "HardCODE",
            image: hardcode,
            dept: "Department of Computer Science and Engineering & Cognizant Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "August 06th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "KodeFIGHTER",
            image: kodefighter,
            dept: "Department of Computer Science and Engineering & Tech Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "November 09th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "LOOPSolve",
            image: loppsolve,
            dept: "Department of Computer Science and Engineering & Google Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "November 19th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "PROBSolvi",
            image: probsolvi,
            dept: "Department of Computer Science and Engineering & Cognizant Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "November 25th",
            sponseredby: "AICTE SPICES",
        },
        {
            eventName: "TECH IT",
            image: techit,
            dept: "Department of Computer Science and Engineering & Google Club",
            firstprice: "Rs. 5000",
            secondprice: "Rs. 3000",
            thirdprice: "Rs. 2000",
            year: "2022",
            date: "August 13th",
            sponseredby: "AICTE SPICES",
        },

    ],
   
];

export default EventsData;